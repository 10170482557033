<template>
  <div>
    <div class="well_frame">
      <h4 class="frame_title" v-if="uuid == ''">申请开票</h4>
      <h4 class="frame_title" v-else>修改开票</h4>
      <el-form :model="form" :rules="rules" ref="applyForm" label-width="120px">
        <el-form-item label="发票类型" prop="type">
          <el-radio-group v-model="form.type" @change="changeMedium">
            <el-radio label="0">普通发票</el-radio>
            <el-radio label="1">专用发票</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="发票内容" prop="content">
          培训费
        </el-form-item>
        <el-form-item label="发票订单" prop="order" >
          <!-- 列表 -->
          <el-table :data="orderList.data" @selection-change="orderSelectChange" ref="orderList" border max-height="300">
            <el-table-column type="selection" width="40"></el-table-column>
            <el-table-column prop="type_text" label="订单类型"></el-table-column>
            <el-table-column prop="order_sn" width="200" label="订单编号"></el-table-column>
            <el-table-column prop="goods_count" label="商品数量"></el-table-column>
            <el-table-column prop="order_amount" label="实付金额">
              <template #default="scope">
                <span>¥&#160;{{scope.row.order_amount}}</span>
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
        <el-form-item label="发票金额" prop="money">
          <span>¥&#160;{{ form.money }}</span>
        </el-form-item>
        <el-form-item label="发票抬头" prop="title">
          <el-input v-model.trim="form.title"/>
        </el-form-item>
        <el-form-item label="公司名称" prop="company" >
          <el-input v-model.trim="form.company"/>
        </el-form-item>
        <el-form-item label="纳税人识别号" prop="taxpayer_num">
          <el-input v-model.trim="form.taxpayer_num"/>
        </el-form-item>
        <el-form-item label="纳税人地址" prop="taxpayer_addr"
                      :rules="form.type == 1 ? rules.taxpayer_addr:[{ required : false },{ max : 63 , message: '请输入63个以内的字符' }] ">
          <el-input v-model.trim="form.taxpayer_addr"/>
        </el-form-item>
        <el-form-item label="纳税人电话" prop="taxpayer_phone"
                      :rules="form.type == 1 ? rules.taxpayer_phone:[{ required : false },{ max : 63 , message: '请输入63个以内的字符' }] ">
          <el-input v-model.trim="form.taxpayer_phone"/>
        </el-form-item>
        <el-form-item label="开户行" prop="account_bank"
                      :rules="form.type == 1 ? rules.account_bank:[{ required : false },{ max : 63 , message: '请输入63个以内的字符' }] ">
          <el-input v-model.trim="form.account_bank"/>
        </el-form-item>
        <el-form-item label="开户行账号" prop="account_num"
                      :rules="form.type == 1 ? rules.account_num:[{ required : false },{ max : 63 , message: '请输入63个以内的字符' }] ">
          <el-input v-model.trim="form.account_num"/>
        </el-form-item>
        <el-form-item label="备注" prop="status_instructe">
          <el-input type="textarea" v-model.trim="form.status_instructe"/>
        </el-form-item>
        <el-form-item label="发票介质" prop="medium">
          <el-radio-group v-model="form.medium">
            <el-radio label="0" >电子发票</el-radio>
            <el-radio label="1" >纸质发票</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="邮箱" prop="email_addr" v-if="form.medium == 0">
          <el-input v-model.trim="form.email_addr" placeholder="请输入邮箱"/>
        </el-form-item>
        <el-form-item label="邮寄地址" required v-if="(form.medium == 1) ">
          <el-row :gutter="10">
            <el-col :span="5">
              <el-form-item prop="region">
                <elui-china-area-dht style="-display: block" v-model="form.region" ref="region"></elui-china-area-dht>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item prop="post_addr" ref="post_addr">
                <el-input v-model.trim="form.post_addr" placeholder="请输入详细地址"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form-item>

        <el-form-item label="收件人" prop="addressee" v-if="(form.type == 1) || (form.type == 0 && form.medium == 1)">
          <el-input v-model.trim="form.addressee" />
        </el-form-item>

        <el-form-item label="收件人电话" prop="reci_phone" v-if="(form.type == 1) || (form.type == 0 && form.medium == 1)">
          <el-input v-model.trim="form.reci_phone" />
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="submitForm('applyForm')">确定</el-button>
          <el-button @click="goPath('/center/ticket')">取消</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
  import {EluiChinaAreaDht} from 'elui-china-area-dht';
  import {createInvoice, changeInvoice, checkInvoice, checkOrder} from 'api/invoice/list'
  import {getOrderList, getInvoice} from 'api/invoice/list'
  import {GetUserState, LoginUser} from 'api/user/auth/login'
  import {fmtOrderInfo} from 'api/settlement/order/admin'
  import { showMessage } from 'api/base/message'
  import {checkTrainPhone, checkEmail, checkTaxpayerNum} from 'utils/common.js'
  import ElDescription from "../../../../components/common/el-descriptions/ElDescription";
  const chinaData = new EluiChinaAreaDht.ChinaArea().chinaAreaflat  //所有省市区对应编码数据
  export default {
    components: {
      ElDescription,
      EluiChinaAreaDht,
    },
    data() {
      return {
        uuid: '',
        value1: '',
        old_order_uuids: [],
        form: {
          id: '',
          type: '0',
          title: '',
          money: 0,
          company: '',
          taxpayer_num: '',
          taxpayer_addr: '',
          taxpayer_phone: '',
          account_bank: '',
          account_num: '',
          post_addr: '',
          email_addr: '',
          medium: '0',
          status: '',
          status_instructe: '',
          region: [],
          order_uuids: [],
          owner_id: '',
          reci_phone:'',
          addressee:'',
          order:'',
        },
        user: {
          user_id: null,
          cellphone: null,
          realname:null
        },
        is_special: true,
        rules: {
          type: [
            {required: true, message: '请选择发票类型', trigger: 'change'}
          ],
          title: [
            {required: true, message: '请输入发票抬头', trigger: 'change'},
            { max : 63 , message: '请输入63个以内的字符' }
          ],
          company: [
            {required: true, message: '请输入公司名称', trigger: 'change'},
            { max : 63 , message: '请输入63个以内的字符' }
          ],
          taxpayer_num: [
            {required: true, validator: checkTaxpayerNum, trigger: 'blur'},
          ],
          taxpayer_addr: [
            {required: true, message: '请输入纳税人地址', trigger: 'change'},
            { max : 63 , message: '请输入63个以内的字符' }
          ],
          addressee: [
            {required: true, message: '请输入收件人', trigger: 'change'},
            { max : 63 , message: '请输入63个以内的字符' }
          ],
          reci_phone: [
            {required: true, validator: checkTrainPhone, trigger: 'blur'},
          ],
          taxpayer_phone: [
            {required: true, validator: checkTrainPhone, trigger: 'blur'},
          ],
          account_bank: [
            {required: true, message: '请输入开户行', trigger: 'change'},
            { max : 63 , message: '请输入63个以内的字符' }
          ],
          account_num: [
            {required: true, message: '请输入开户行账号', trigger: 'change'},
            { max : 63 , message: '请输入63个以内的字符' }
          ],
          medium: [
            {required: true, message: '请选择发票介质', trigger: 'change'}
          ],
          email_addr: [
            {required: true, validator: checkEmail, trigger: 'blur'},
            { max : 63 , message: '请输入63个以内的字符' }
          ],
          region: [
            {required: true, message: '请选择区域', trigger: 'change'}
          ],
          post_addr: [
            { required: true, message: '请输入详细地址', trigger: 'blur'},
            { max : 63 , message: '请输入63个以内的字符'}
          ],
          order: [
            { required: true, message: '请选择至少一个订单', trigger: 'change'},
          ],
        },
        orderList: {
          data: [],
          selectedRows: [],
        },
        userList: {
          owner_id: '',
//          cellphone:'',
        }
      }
    },
    mounted() {
      if (this.$route.query.uuid != undefined) {
        this.uuid = this.$route.query.uuid
        this.getData()
      }
      else {
        this.getOwner()
      }
    },
    methods: {
      getData() {
        getInvoice(this.uuid).then((res) => {
          let data = res.data
          let post_addr = JSON.parse(data['post_addr'])
          let post_addr_land = []
          if(data['medium'] == 1){
            if (typeof post_addr[1] == "string"){
                showMessage('检测到此开票有过移动端操作，切换至网页端需要重新选择邮寄地址！','warning')
                post_addr_land = []
            }else{
                if (post_addr[2] == null){
                    post_addr_land = [post_addr[0].value, post_addr[1].value]
                }else {
                  post_addr_land = [post_addr[0].value, post_addr[1].value, post_addr[2].value]
                }
            }



          }else {
              post_addr_land = []
          }
          this.old_order_uuids = data.order_uuids.split(',')
          this.form = {
            id: data.id,
            type: data.type,
            title: data.title,
            money: data.money,
            company: data.company,
            taxpayer_num: data.taxpayer_num,
            taxpayer_addr: data.taxpayer_addr,
            taxpayer_phone: data.taxpayer_phone,
            account_bank: data.account_bank,
            account_num: data.account_num,
            post_addr: post_addr[3],
            email_addr: data.email_addr,
            medium: data.medium,
            status: data.status,
            status_instructe: data.status_instructe,
            order_uuids: [],
            region: post_addr_land,
            addressee : data.addressee,
            reci_phone : data.reci_phone
          }
          this.getOwner()
        }).catch(err => {
          this.$alert(`${ err }`, '提示', {
            confirmButtonText: '确定',
          });
        });
      },
      getOrderData() {
        const that = this
        var data = {}
        if (this.uuid !== ''){
          data = {
            'owner_id': this.user.user_id,
            'select_status': '2',
            'old_order_uuids': this.old_order_uuids
          }
        } else{
          data = {
            'owner_id': this.user.user_id,
            'select_status': '2'
          }
        }
        getOrderList(data).then((res) => {
          this.orderList.data = [];
          res.data.forEach(item => {
            let filled_item = fmtOrderInfo(item);
            this.orderList.data.push(filled_item);
          });
          that.$nextTick(() => {
            that.orderList.data.forEach(row => {
              if(that.old_order_uuids.indexOf(row.uuid) >= 0){
                that.$refs.orderList.toggleRowSelection(row, true)
              }
            })
          })
        })
      },
      orderSelectChange(val) {
        this.orderList.selectedRows = val;
        this.form.order = val;
        var lengths = this.orderList.selectedRows.length;
        var uuids = this.orderList.selectedRows.map(item => {
          return item.uuid
        }).join()
        var money = this.orderList.selectedRows.map(item => {
          return item.order_amount
        })
        let total = 0
        money.forEach((value) => {
          total = total + value
        })
        const totalStr = total.toString();
        const potIndex = totalStr.indexOf('.');
        let newTotal;
        if(potIndex !== -1) {
            newTotal = totalStr.slice(0, potIndex + 3);
        } else {
            newTotal = totalStr;
        }
        this.form.money = newTotal
        this.form.order_uuids = uuids
        this.form.invoice_num = lengths
      },
      submitForm(formName) {
        let order_uuid = this.form.order_uuids
        let order_filters = {'uuid_in': order_uuid}
        this.$refs[formName].validate((valid) => {
          if (valid) {
            checkOrder(order_filters).then((res) => {
              if (res.result == 0){
                let invoice_filters ={'new_order_uuids':order_uuid,
                                      'old_order_uuids': this.old_order_uuids}
                checkInvoice(invoice_filters).then((res) => {
                  if (res.result == 1){
                        var values = Object.assign({}, this.form)
                        if(values['medium'] == 0){
                          values['addressee'] = '';
                          values['reci_phone'] = ''
                        }
                        values['post_addr'] = [chinaData[this.form.region[0]],
                                                chinaData[this.form.region[1]],
                                                chinaData[this.form.region[2]],
                                                this.form.post_addr]
                        if (this.uuid != '') {
                          var invoice_id = this.form.id
                          changeInvoice(invoice_id, values).then(() => {
                            this.goPath('/center/ticket')
                          })
                        } else {
                          createInvoice(values).then(() => {
                            this.goPath('/center/ticket')
                          })
                        }
                  }else {
                      showMessage('所选订单中存在已开票订单，请刷新页面重新选择！','error')
                  }
                })
              }else {
                  showMessage('所选订单中存在已被删除订单，请刷新页面重新选择！','error')
              }
            })
          }else {
             showMessage("所填内容有误(请按照要求填写)！",'error');
             return false;
          }
        });
      },
      goPath(path) {
        this.$router.push(path);
      },
      getOwner(){
        GetUserState().then((res) => {
          if (res.id) {
            this.user.user_id = res.id
            this.user.cellphone = res.cellphone
            this.user.realname = res.realname

            var owner_id = this.user.user_id
            var reci_phone = this.user.cellphone
            var addressee = this.user.realname

            this.form.owner_id = this.form.owner_id == '' ? owner_id : this.form.owner_id
            this.form.reci_phone = this.form.reci_phone == '' ? reci_phone : this.form.reci_phone
            this.form.addressee = this.form.addressee == '' ? addressee : this.form.addressee
            this.getOrderData()
          }
          return owner_id
        })
      },
      changeMedium(value){
        if (value == 1){
            this.form.medium = '1'
        }
        if (value == 0){
            this.form.medium = '0'
        }
      },
    }
  }
</script>

<style lang="scss" scoped>
  .el-table--border {
    border-top: none;
  }
</style>
